// extracted by mini-css-extract-plugin
export var aboutMainCircleBlurBlue = "TwoColumVideo-module--aboutMainCircleBlurBlue--fdb5e";
export var bHeading = "TwoColumVideo-module--bHeading--3a3db";
export var development = "TwoColumVideo-module--development--7d97b";
export var gdnPromotePic = "TwoColumVideo-module--gdnPromotePic--8fbf6";
export var head = "TwoColumVideo-module--head--06526";
export var heroBackgroundCircleLeft = "TwoColumVideo-module--heroBackgroundCircleLeft--6d146";
export var heroBackgroundCircleLeftStory = "TwoColumVideo-module--heroBackgroundCircleLeftStory--71da9";
export var heroBackgroundCircleLeftStorySecond = "TwoColumVideo-module--heroBackgroundCircleLeftStorySecond--27c80";
export var heroBackgroundInnerCircleLeft = "TwoColumVideo-module--heroBackgroundInnerCircleLeft--2ed95";
export var heroBackgroundInnerCircleLeftSecond = "TwoColumVideo-module--heroBackgroundInnerCircleLeftSecond--7f441";
export var knowUs = "TwoColumVideo-module--knowUs--08fe1";
export var knowUsHeading = "TwoColumVideo-module--knowUsHeading--34a71";
export var knowUsImage = "TwoColumVideo-module--knowUsImage--a5d8f";
export var knowUsImageDiv = "TwoColumVideo-module--knowUsImageDiv--81589";
export var knowUsOurStory = "TwoColumVideo-module--knowUsOurStory--323dc";
export var knowUsOurStoryText = "TwoColumVideo-module--knowUsOurStoryText--ab41b";
export var knowUsText = "TwoColumVideo-module--knowUsText--c0cf5";
export var ourStoryDescPara = "TwoColumVideo-module--ourStoryDescPara--f42ed";
export var ourStoryImg = "TwoColumVideo-module--ourStoryImg--e5d4e";
export var ourStoryImgDiv = "TwoColumVideo-module--ourStoryImgDiv--16361";
export var ourStoryText = "TwoColumVideo-module--ourStoryText--c1226";
export var storyPromotePic = "TwoColumVideo-module--storyPromotePic--090f0";
export var storyPromotePicSecond = "TwoColumVideo-module--storyPromotePicSecond--32bb5";
export var testimonialVideo = "TwoColumVideo-module--testimonialVideo--e75ac";
export var topHeading = "TwoColumVideo-module--topHeading--f52f0";
export var videoWrapper = "TwoColumVideo-module--videoWrapper--d99af";